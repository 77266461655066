import React, { useState, useEffect, useRef } from "react";
import addTable from "../../src/assets/icons/addTable.svg";
import "../../src/assets/styles/style.css";
import Tablecard from "../../src/components/Tablecard";
import TableGenerator from "../../src/components/table/tableGeneratorUser";
import { ToastContainer, toast } from "react-toastify";
import AuthService from "../../src/services/AuthServices";
import ConfirmDialog from "../../src/components/CustomDialog/ConfirmDialog";
import Header from "../layoutUser/header/header";
import Sidebar from "../layoutUser/sidebar/sidebar";

function BookingUser(props) {
  const [selectedPlace, setSelectedPlace] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const toastId = useRef([]);
  const [selected, setSelected] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [status, setStatus] = useState("available");

  const handleOpenEditModal = (place) => {
    setSelected(place);
    setName(place.name);
    setNbtable(place.capacity);
    setIsBookable(place.isBookable);
    setDescription(place.description);
    setStatus(place.status);
    setIsEditModalOpen(true);
  };

  const handleOpenDeleteModal = (place) => {
    setSelected(place);
    setIsDeleteModalOpen(true);
  };

  const [nbtable, setNbtable] = useState(4);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [isBookable, setIsBookable] = useState(true);

  const [userId, setUserId] = useState();
  const [token, setToken] = useState();
  const [places, setPlaces] = useState([]);
  const [filteredPlace, setFilteredPlace] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getUserData = () => {
    const tok = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    setToken(tok);
    setUserId(id);
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (token) {
      handlePlaces();
    }
  }, [token]);

  useEffect(() => {
    if (places && places.length > 0) {
      setFilteredPlace(places);
    }
  }, [places]);

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  const handleAddTableClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsDeleteModalOpen(false);
    setIsEditModalOpen(false);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleIsBookableChange = (event) => {
    setIsBookable(event.target.value === "true");
  };

  const handleAddPlace = async () => {
    try {
      if (!name) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Please Choose the name of the place, it's required!"
          );
        }
        return;
      }

      if (!nbtable || nbtable <= 0) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(
            "Capacity is required and must be a positive number."
          );
        }
        return;
      }

      const response = await AuthService.addPlace(
        userId,
        token,
        name,
        nbtable,
        description,
        isBookable
      );
      if (response.message) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(response.message);
        }
        setIsModalOpen(false);
        handlePlaces();
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(response.error);
        }
      }
    } catch (error) {
      console.log(error);
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "An error occurred during form submission"
        );
      }
    }
  };

  const handlePlaces = async () => {
    setIsLoading(true);
    try {
      if (token && userId) {
        const placesResponse = await AuthService.getPlaces(token);
        let bookedPlaceIds = [];

        if (placesResponse.data) {
          const ordersResponse = await AuthService.getUserOrders(userId, token);

          // Map the booked place IDs and also store other order details
          const ordersMap = {};
          if (Array.isArray(ordersResponse)) {
            ordersResponse.forEach((order) => {
              bookedPlaceIds.push(order.place_id);
              ordersMap[order.place_id] = order; // Storing order details by place_id
            });
          } else if (ordersResponse && ordersResponse.data) {
            ordersResponse.data.forEach((order) => {
              bookedPlaceIds.push(order.place_id);
              ordersMap[order.place_id] = order;
            });
          }

          // Filter places to only include those booked by the user and add order details
          const bookedPlaces = placesResponse.data
            .filter((place) => bookedPlaceIds.includes(place.id))
            .map((place) => {
              return {
                ...place,
                ...ordersMap[place.id], // Merging order details into place
              };
            });

          if (bookedPlaces.length > 0) {
            setPlaces(bookedPlaces);
            setFilteredPlace(bookedPlaces); // Only show booked places
          } else {
            if (!toast.isActive(toastId.current)) {
              toastId.current = toast.warning(
                "No places available for your bookings."
              );
            }
            setPlaces([]);
            setFilteredPlace([]);
          }
        } else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(placesResponse.error);
          }
        }
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Token or User ID missing");
        }
      }
    } catch (error) {
      console.log(error);
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Error while fetching Places");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilter = (text) => {
    const filtered = places.filter((place) => place.status === text);
    setFilteredPlace(filtered);
  };

  const resetFilter = () => {
    setFilteredPlace(places);
  };
  const handleEditPlace = async (_id) => {
    try {
      const response = await AuthService.updatePlace(
        userId,
        token,
        _id,
        name,
        status,
        nbtable,
        description,
        isBookable
      );

      if (response.message) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(response.message);
        }
        await handlePlaces();
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(response.error);
        }
      }
    } catch (error) {
      console.log(error);
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Une erreur s'est produite lors de la soumission du formulaire"
        );
      }
    }
    handleCloseModal();
  };

  const handleDeletePlace = async (_id) => {
    try {
      const response = await AuthService.deletePlace(userId, token, _id);

      if (response.message) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(response.message);
        }
        await handlePlaces();
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(response.error);
        }
      }
    } catch (error) {
      console.log(error);
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Une erreur s'est produite lors de la soumission du formulaire"
        );
      }
    }
    handleCloseModal();
  };

  return (
    <div className="container">
      <Header />
      <Sidebar />
      <div className="layout-container">
        <div className="padding">
          <div className="table-header-content itemT">
            <div className="rowT">
              <span
                onClick={() => {
                  handleItemClick(0);
                  resetFilter();
                }}
                className={selectedItem === 0 ? "selected" : ""}
              >
                All
              </span>
              <span
                onClick={() => {
                  handleItemClick(1);
                  handleFilter("taken");
                }}
                className={selectedItem === 1 ? "selected" : ""}
              >
                Taken
              </span>
              <span
                onClick={() => {
                  handleItemClick(2);
                  handleFilter("reserved");
                }}
                className={selectedItem === 2 ? "selected" : ""}
              >
                Reserved
              </span>
              <span
                onClick={() => {
                  handleItemClick(3);
                  handleFilter("available");
                }}
                className={selectedItem === 3 ? "selected" : ""}
              >
                Available
              </span>
            </div>
            <div className="icon-addtable" onClick={handleAddTableClick}>
              <img src={addTable} alt="Add Table" />
            </div>
          </div>

          {isModalOpen && (
            <div className="modal-overlay">
              <div className="modal">
                <div className="form">
                  <div className="row">
                    <div className="form-group">
                      <label>ADD PLACE</label>
                    </div>
                    <div className="form-group">
                      <button
                        className="close-button"
                        onClick={handleCloseModal}
                      >
                        X
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group" style={{ width: "45%" }}>
                      <label htmlFor="name">NAME:</label>
                      <input
                        type="text"
                        id="name"
                        placeholder="PLACE 9"
                        value={name}
                        onChange={handleNameChange}
                      />
                    </div>
                    <div className="form-group" style={{ width: "45%" }}>
                      <label htmlFor="seats">CAPACITY:</label>
                      <input
                        type="number"
                        id="seats"
                        value={nbtable}
                        min={1}
                        max={40}
                        onChange={(e) => setNbtable(Number(e.target.value))}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div style={{ width: "45%" }}>
                      <div className="form-group">
                        <label htmlFor="reservation">
                          POSSIBILITY TO BOOK:
                        </label>
                        <select
                          id="reservation"
                          value={isBookable}
                          onChange={handleIsBookableChange}
                        >
                          <option value={true}>YES</option>
                          <option value={false}>NO</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>DESCRIPTION:</label>
                        <textarea
                          rows={6}
                          cols={50}
                          type="text"
                          placeholder="Place description"
                          value={description}
                          onChange={handleDescriptionChange}
                        />
                      </div>
                    </div>
                    <div className="form-group" style={{ width: "45%" }}>
                      <label>ICÔNE PREVIEW</label>
                      <div style={{ width: "100%" }}>
                        <Tablecard totalChairs={nbtable} />
                      </div>
                    </div>
                  </div>

                  <div className="rowL">
                    <button onClick={handleAddPlace} className="button">
                      ADD PLACE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isDeleteModalOpen && (
            <ConfirmDialog
              message={`Are you sure you want to delete (${selected.name})?`}
              onConfirm={() => handleDeletePlace(selected.id)}
              onCancel={handleCloseModal}
              texConfirm={"YES"}
              textCancel={"NO"}
            />
          )}
          {isEditModalOpen && (
            <div className="modal-overlay">
              <div className="modal">
                <div className="form">
                  <div className="row">
                    <div className="form-group">
                      <label>EDIT PLACE</label>
                    </div>
                    <div className="form-group">
                      <button
                        className="close-button"
                        onClick={handleCloseModal}
                      >
                        X
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group" style={{ width: "45%" }}>
                      <label htmlFor="name">NAME:</label>
                      <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="form-group" style={{ width: "45%" }}>
                      <label htmlFor="seats">CAPACITY:</label>
                      <input
                        type="number"
                        id="seats"
                        value={nbtable}
                        min={1}
                        max={40}
                        onChange={(e) => setNbtable(Number(e.target.value))}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ width: "45%" }}>
                      <div className="form-group">
                        <label htmlFor="reservation">
                          POSSIBILITY TO BOOK:
                        </label>
                        <select
                          id="reservation"
                          value={isBookable}
                          onChange={(e) =>
                            setIsBookable(e.target.value === "true")
                          }
                        >
                          <option value={true}>YES</option>
                          <option value={false}>NO</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label>STATUS:</label>
                        <div className="checkbox-group">
                          <label>
                            <input
                              type="radio"
                              name="status"
                              value="available"
                              checked={status === "available"}
                              onChange={(e) => setStatus(e.target.value)}
                            />
                            Available
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="status"
                              value="reserved"
                              checked={status === "reserved"}
                              onChange={(e) => setStatus(e.target.value)}
                            />
                            Reserved
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="status"
                              value="taken"
                              checked={status === "taken"}
                              onChange={(e) => setStatus(e.target.value)}
                            />
                            Taken
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>DESCRIPTION:</label>
                        <textarea
                          rows={3}
                          cols={40}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group" style={{ width: "45%" }}>
                      <label>ICÔNE PREVIEW</label>
                      <div style={{ width: "100%" }}>
                        <Tablecard totalChairs={nbtable} />
                      </div>
                    </div>
                  </div>
                  <div className="rowL">
                    <button
                      onClick={() => handleEditPlace(selected.id)}
                      className="button"
                    >
                      SAVE CHANGES
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isLoading ? (
            <div className="loader"></div>
          ) : (
            <div className="scrollable-container">
              <div className="rowM">
                {filteredPlace && filteredPlace.length > 0 ? (
                  filteredPlace.map((place, index) => (
                    <TableGenerator
                      key={index}
                      action={() => setSelectedPlace(index)}
                      name={place.name}
                      totalChairs={place.capacity}
                      status={place.status}
                      employer_id={place.employer_id} // New field
                      braid_id={place.braid_id} // New field
                      price={place.price} // New field
                      order_status={place.order_status} // New field
                      startTime={place.startTime} // New field
                      endTime={place.endTime} // New field
                      date={place.date} // New field
                      isSelected={selectedPlace === index}
                      onEdit={() => handleOpenEditModal(place)}
                      onDelete={() => handleOpenDeleteModal(place)}
                    />
                  ))
                ) : (
                  <div className="centered-text">
                    <p>No places available</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer position="top-center" limit={1} />
    </div>
  );
}

export default BookingUser;

// import React, { useState, useEffect, useRef } from "react";
// import addTable from "../../src/assets/icons/addTable.svg";
// import "../../src/assets/styles/style.css";
// import Tablecard from "../../src/components/Tablecard";
// import TableGenerator from "../../src/components/table/tableGeneratorUser";
// import { ToastContainer, toast } from "react-toastify";
// import AuthService from "../../src/services/AuthServices";
// import ConfirmDialog from "../../src/components/CustomDialog/ConfirmDialog";
// import Header from "../layoutUser/header/header";
// import Sidebar from "../layoutUser/sidebar/sidebar";

// function BookingUser(props) {
//   const [selectedPlace, setSelectedPlace] = useState(0);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState(0);
//   const toastId = useRef([]);
//   const [selected, setSelected] = useState(null);
//   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
//   const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//   const [status, setStatus] = useState("available");

//   const handleOpenEditModal = (place) => {
//     setSelected(place);
//     setName(place.name);
//     setNbtable(place.capacity);
//     setIsBookable(place.isBookable);
//     setDescription(place.description);
//     setStatus(place.status);
//     setIsEditModalOpen(true);
//   };

//   const handleOpenDeleteModal = (place) => {
//     setSelected(place);
//     setIsDeleteModalOpen(true);
//   };

//   const [nbtable, setNbtable] = useState(4);
//   const [description, setDescription] = useState("");
//   const [name, setName] = useState("");
//   const [isBookable, setIsBookable] = useState(true);

//   const [userId, setUserId] = useState();
//   const [token, setToken] = useState();
//   const [places, setPlaces] = useState([]);
//   const [filteredPlace, setFilteredPlace] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const getUserData = () => {
//     const tok = localStorage.getItem("token");
//     const id = localStorage.getItem("id");
//     setToken(tok);
//     setUserId(id);
//   };

//   useEffect(() => {
//     getUserData();
//   }, []);

//   useEffect(() => {
//     if (token) {
//       handlePlaces();
//     }
//   }, [token]);

//   useEffect(() => {
//     if (places && places.length > 0) {
//       setFilteredPlace(places);
//     }
//   }, [places]);

//   const handleItemClick = (index) => {
//     setSelectedItem(index);
//   };

//   const handleAddTableClick = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setIsDeleteModalOpen(false);
//     setIsEditModalOpen(false);
//   };

//   const handleDescriptionChange = (event) => {
//     setDescription(event.target.value);
//   };

//   const handleNameChange = (event) => {
//     setName(event.target.value);
//   };

//   const handleIsBookableChange = (event) => {
//     setIsBookable(event.target.value === "true");
//   };

//   const handleAddPlace = async () => {
//     try {
//       if (!name) {
//         if (!toast.isActive(toastId.current)) {
//           toastId.current = toast.error(
//             "Please Choose the name of the place, it's required!"
//           );
//         }
//         return;
//       }

//       if (!nbtable || nbtable <= 0) {
//         if (!toast.isActive(toastId.current)) {
//           toastId.current = toast.error(
//             "Capacity is required and must be a positive number."
//           );
//         }
//         return;
//       }

//       const response = await AuthService.addPlace(
//         userId,
//         token,
//         name,
//         nbtable,
//         description,
//         isBookable
//       );
//       if (response.message) {
//         if (!toast.isActive(toastId.current)) {
//           toastId.current = toast.success(response.message);
//         }
//         setIsModalOpen(false);
//         handlePlaces();
//       } else {
//         if (!toast.isActive(toastId.current)) {
//           toastId.current = toast.error(response.error);
//         }
//       }
//     } catch (error) {
//       console.log(error);
//       if (!toast.isActive(toastId.current)) {
//         toastId.current = toast.error(
//           "An error occurred during form submission"
//         );
//       }
//     }
//   };

//   const handlePlaces = async () => {
//     setIsLoading(true);
//     try {
//       if (token && userId) {
//         const placesResponse = await AuthService.getPlaces(token);
//         let bookedPlaceIds = [];

//         if (placesResponse.data) {
//           const ordersResponse = await AuthService.getUserOrders(userId, token);

//           // Map the booked place IDs and also store other order details
//           const ordersMap = {};
//           if (Array.isArray(ordersResponse)) {
//             ordersResponse.forEach((order) => {
//               bookedPlaceIds.push(order.place_id);
//               ordersMap[order.place_id] = order; // Storing order details by place_id
//             });
//           } else if (ordersResponse && ordersResponse.data) {
//             ordersResponse.data.forEach((order) => {
//               bookedPlaceIds.push(order.place_id);
//               ordersMap[order.place_id] = order;
//             });
//           }

//           // Filter places to only include those booked by the user and add order details
//           const bookedPlaces = placesResponse.data
//             .filter((place) => bookedPlaceIds.includes(place.id))
//             .map((place) => {
//               return {
//                 ...place,
//                 ...ordersMap[place.id], // Merging order details into place
//               };
//             });

//           if (bookedPlaces.length > 0) {
//             setPlaces(bookedPlaces);
//             setFilteredPlace(bookedPlaces); // Only show booked places
//           } else {
//             if (!toast.isActive(toastId.current)) {
//               toastId.current = toast.warning(
//                 "No places available for your bookings."
//               );
//             }
//             setPlaces([]);
//             setFilteredPlace([]);
//           }
//         } else {
//           if (!toast.isActive(toastId.current)) {
//             toastId.current = toast.error(placesResponse.error);
//           }
//         }
//       } else {
//         if (!toast.isActive(toastId.current)) {
//           toastId.current = toast.error("Token or User ID missing");
//         }
//       }
//     } catch (error) {
//       console.log(error);
//       if (!toast.isActive(toastId.current)) {
//         toastId.current = toast.error("Error while fetching Places");
//       }
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleFilter = (text) => {
//     const filtered = places.filter((place) => place.status === text);
//     setFilteredPlace(filtered);
//   };

//   const resetFilter = () => {
//     setFilteredPlace(places);
//   };
//   const handleEditPlace = async (_id) => {
//     try {
//       const response = await AuthService.updatePlace(
//         userId,
//         token,
//         _id,
//         name,
//         status,
//         nbtable,
//         description,
//         isBookable
//       );

//       if (response.message) {
//         if (!toast.isActive(toastId.current)) {
//           toastId.current = toast.success(response.message);
//         }
//         await handlePlaces();
//       } else {
//         if (!toast.isActive(toastId.current)) {
//           toastId.current = toast.error(response.error);
//         }
//       }
//     } catch (error) {
//       console.log(error);
//       if (!toast.isActive(toastId.current)) {
//         toastId.current = toast.error(
//           "Une erreur s'est produite lors de la soumission du formulaire"
//         );
//       }
//     }
//     handleCloseModal();
//   };

//   const handleDeletePlace = async (_id) => {
//     try {
//       const response = await AuthService.deletePlace(userId, token, _id);

//       if (response.message) {
//         if (!toast.isActive(toastId.current)) {
//           toastId.current = toast.success(response.message);
//         }
//         await handlePlaces();
//       } else {
//         if (!toast.isActive(toastId.current)) {
//           toastId.current = toast.error(response.error);
//         }
//       }
//     } catch (error) {
//       console.log(error);
//       if (!toast.isActive(toastId.current)) {
//         toastId.current = toast.error(
//           "Une erreur s'est produite lors de la soumission du formulaire"
//         );
//       }
//     }
//     handleCloseModal();
//   };

//   return (
//     <div className="container">
//       <Header />
//       <Sidebar />
//       <div className="layout-container">
//         <div className="padding">
//           <div className="table-header-content itemT">
//             <div className="rowT">
//               <span
//                 onClick={() => {
//                   handleItemClick(0);
//                   resetFilter();
//                 }}
//                 className={selectedItem === 0 ? "selected" : ""}
//               >
//                 All
//               </span>
//               <span
//                 onClick={() => {
//                   handleItemClick(1);
//                   handleFilter("taken");
//                 }}
//                 className={selectedItem === 1 ? "selected" : ""}
//               >
//                 Taken
//               </span>
//               <span
//                 onClick={() => {
//                   handleItemClick(2);
//                   handleFilter("reserved");
//                 }}
//                 className={selectedItem === 2 ? "selected" : ""}
//               >
//                 Reserved
//               </span>
//               <span
//                 onClick={() => {
//                   handleItemClick(3);
//                   handleFilter("available");
//                 }}
//                 className={selectedItem === 3 ? "selected" : ""}
//               >
//                 Available
//               </span>
//             </div>
//             <div className="icon-addtable" onClick={handleAddTableClick}>
//               <img src={addTable} alt="Add Table" />
//             </div>
//           </div>

//           {isModalOpen && (
//             <div className="modal-overlay">
//               <div className="modal">
//                 <div className="form">
//                   <div className="row">
//                     <div className="form-group">
//                       <label>ADD PLACE</label>
//                     </div>
//                     <div className="form-group">
//                       <button
//                         className="close-button"
//                         onClick={handleCloseModal}
//                       >
//                         X
//                       </button>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="form-group" style={{ width: "45%" }}>
//                       <label htmlFor="name">NAME:</label>
//                       <input
//                         type="text"
//                         id="name"
//                         placeholder="PLACE 9"
//                         value={name}
//                         onChange={handleNameChange}
//                       />
//                     </div>
//                     <div className="form-group" style={{ width: "45%" }}>
//                       <label htmlFor="seats">CAPACITY:</label>
//                       <input
//                         type="number"
//                         id="seats"
//                         value={nbtable}
//                         min={1}
//                         max={40}
//                         onChange={(e) => setNbtable(Number(e.target.value))}
//                       />
//                     </div>
//                   </div>

//                   <div className="row">
//                     <div style={{ width: "45%" }}>
//                       <div className="form-group">
//                         <label htmlFor="reservation">
//                           POSSIBILITY TO BOOK:
//                         </label>
//                         <select
//                           id="reservation"
//                           value={isBookable}
//                           onChange={handleIsBookableChange}
//                         >
//                           <option value={true}>YES</option>
//                           <option value={false}>NO</option>
//                         </select>
//                       </div>
//                       <div className="form-group">
//                         <label>DESCRIPTION:</label>
//                         <textarea
//                           rows={6}
//                           cols={50}
//                           type="text"
//                           placeholder="Place description"
//                           value={description}
//                           onChange={handleDescriptionChange}
//                         />
//                       </div>
//                     </div>
//                     <div className="form-group" style={{ width: "45%" }}>
//                       <label>ICÔNE PREVIEW</label>
//                       <div style={{ width: "100%" }}>
//                         <Tablecard totalChairs={nbtable} />
//                       </div>
//                     </div>
//                   </div>

//                   <div className="rowL">
//                     <button onClick={handleAddPlace} className="button">
//                       ADD PLACE
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}

//           {isDeleteModalOpen && (
//             <ConfirmDialog
//               message={`Are you sure you want to delete (${selected.name})?`}
//               onConfirm={() => handleDeletePlace(selected.id)}
//               onCancel={handleCloseModal}
//               texConfirm={"YES"}
//               textCancel={"NO"}
//             />
//           )}
//           {isEditModalOpen && (
//             <div className="modal-overlay">
//               <div className="modal">
//                 <div className="form">
//                   <div className="row">
//                     <div className="form-group">
//                       <label>EDIT PLACE</label>
//                     </div>
//                     <div className="form-group">
//                       <button
//                         className="close-button"
//                         onClick={handleCloseModal}
//                       >
//                         X
//                       </button>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div className="form-group" style={{ width: "45%" }}>
//                       <label htmlFor="name">NAME:</label>
//                       <input
//                         type="text"
//                         id="name"
//                         value={name}
//                         onChange={(e) => setName(e.target.value)}
//                       />
//                     </div>
//                     <div className="form-group" style={{ width: "45%" }}>
//                       <label htmlFor="seats">CAPACITY:</label>
//                       <input
//                         type="number"
//                         id="seats"
//                         value={nbtable}
//                         min={1}
//                         max={40}
//                         onChange={(e) => setNbtable(Number(e.target.value))}
//                       />
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div style={{ width: "45%" }}>
//                       <div className="form-group">
//                         <label htmlFor="reservation">
//                           POSSIBILITY TO BOOK:
//                         </label>
//                         <select
//                           id="reservation"
//                           value={isBookable}
//                           onChange={(e) =>
//                             setIsBookable(e.target.value === "true")
//                           }
//                         >
//                           <option value={true}>YES</option>
//                           <option value={false}>NO</option>
//                         </select>
//                       </div>

//                       <div className="form-group">
//                         <label>STATUS:</label>
//                         <div className="checkbox-group">
//                           <label>
//                             <input
//                               type="radio"
//                               name="status"
//                               value="available"
//                               checked={status === "available"}
//                               onChange={(e) => setStatus(e.target.value)}
//                             />
//                             Available
//                           </label>
//                           <label>
//                             <input
//                               type="radio"
//                               name="status"
//                               value="reserved"
//                               checked={status === "reserved"}
//                               onChange={(e) => setStatus(e.target.value)}
//                             />
//                             Reserved
//                           </label>
//                           <label>
//                             <input
//                               type="radio"
//                               name="status"
//                               value="taken"
//                               checked={status === "taken"}
//                               onChange={(e) => setStatus(e.target.value)}
//                             />
//                             Taken
//                           </label>
//                         </div>
//                       </div>
//                       <div className="form-group">
//                         <label>DESCRIPTION:</label>
//                         <textarea
//                           rows={3}
//                           cols={40}
//                           value={description}
//                           onChange={(e) => setDescription(e.target.value)}
//                         />
//                       </div>
//                     </div>
//                     <div className="form-group" style={{ width: "45%" }}>
//                       <label>ICÔNE PREVIEW</label>
//                       <div style={{ width: "100%" }}>
//                         <Tablecard totalChairs={nbtable} />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="rowL">
//                     <button
//                       onClick={() => handleEditPlace(selected.id)}
//                       className="button"
//                     >
//                       SAVE CHANGES
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}

//           {isLoading ? (
//             <div className="loader"></div>
//           ) : (
//             <div className="scrollable-container">
//               <div className="rowM">
//                 {filteredPlace && filteredPlace.length > 0 ? (
//                   filteredPlace.map((place, index) => (
//                     <TableGenerator
//                       key={index}
//                       action={() => setSelectedPlace(index)}
//                       name={place.name}
//                       totalChairs={place.capacity}
//                       status={place.status}
//                       employer_id={place.employer_id} // New field
//                       braid_id={place.braid_id} // New field
//                       price={place.price} // New field
//                       order_status={place.order_status} // New field
//                       startTime={place.startTime} // New field
//                       endTime={place.endTime} // New field
//                       date={place.date} // New field
//                       isSelected={selectedPlace === index}
//                       onEdit={() => handleOpenEditModal(place)}
//                       onDelete={() => handleOpenDeleteModal(place)}
//                     />
//                   ))
//                 ) : (
//                   <div className="centered-text">
//                     <p>No places available</p>
//                   </div>
//                 )}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <ToastContainer position="top-center" limit={1} />
//     </div>
//   );
// }

// export default BookingUser;
