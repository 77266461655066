import React, { useState, useRef, useEffect } from "react";
import "../../assets/styles/style.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ToastContainer, toast } from "react-toastify";
import AuthService from "../../services/AuthServices";
import BraidsForm from "../../components/BraidsForm";

function MenuCenter(props) {
  const toastId = useRef(null);
  const [userId, setUserId] = useState();
  const [token, setToken] = useState();
  const [categories, setCategories] = useState([]);
  const [allTypes, setAllTypes] = useState([]);
  const [allBraids, setAllBraids] = useState([]);
  const [filteredBraids, setFilteredBraids] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [hoveredTypes, setHoveredTypes] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState(1000);
  const rowItemRef = useRef(null);
  const timerRef = useRef(null);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const getUserData = () => {
    const tok = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    setToken(tok);
    setUserId(id);
  };

  const fetchData = async (fetchFunction, successCallback, errorMessage) => {
    setLoading(true); // Start loading
    try {
      if (token && userId) {
        const response = await fetchFunction(userId, token);
        if (response.data) {
          successCallback(response.data);
        } else {
          toast.error(response.error);
        }
      } else {
        toast.error("Token or User ID is missing");
      }
    } catch (error) {
      toast.error(errorMessage);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleCategories = () =>
    fetchData(
      AuthService.getBraidsCategories,
      setCategories,
      "Error fetching categories"
    );
  const handleTypes = () =>
    fetchData(AuthService.getAllTypes, setAllTypes, "Error fetching types");
  const handleBraids = () =>
    fetchData(AuthService.getAllBraids, setAllBraids, "Error fetching braids");

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (userId && token) {
      handleCategories();
      handleTypes();
      handleBraids();
    }
  }, [userId, token]);

  const handleMouseEnter = (index, categoryId) => {
    clearTimeout(timerRef.current);
    setHoveredIndex(index);
    const filteredTypes = allTypes.filter(
      (type) => type.type_categories_id === categoryId
    );
    setHoveredTypes(filteredTypes);
    setShowSubMenu(true);
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setShowSubMenu(false);
    }, 200);
  };

  const handleSubMenuMouseEnter = () => {
    clearTimeout(timerRef.current);
    setShowSubMenu(true);
  };

  const handleSubMenuMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setShowSubMenu(false);
    }, 0);
  };

  const handleFilter = (idCategorie) => {
    const filtered = allBraids.filter(
      (braid) => braid.braid_type_id === idCategorie
    );
    setFilteredBraids(filtered);
  };

  const handleResetFilter = () => {
    setFilteredBraids(allBraids);
  };

  useEffect(() => {
    if (allBraids.length > 0) {
      setFilteredBraids(allBraids);
    }
  }, [allBraids]);

  const handleBraidClick = (id) => {
    const selected = allBraids.find((braid) => braid.id === id);
    // Do something with the selected braid if needed
  };

  return (
    <div className={`${isSmallScreen ? "main-contentH1" : "main-contentH"}`}>
      <div
        className={`${isSmallScreen ? "column" : "row"}`}
        style={{ flex: 1 }}
      >
        <div
          className="main-content"
          style={{ width: isSmallScreen ? "100%" : "100%" }}
        >
          <div className="padding">
            <div className="menuItem">
              <div className="rowItem itemM" ref={rowItemRef}>
                <span
                  className={selectedItem === 1000 ? "selected" : ""}
                  onClick={() => {
                    setSelectedItem(1000);
                    handleResetFilter();
                  }}
                >
                  All
                </span>
                {categories.length > 0 ? (
                  categories.map((item, index) => (
                    <span
                      key={item.id}
                      onMouseEnter={() => handleMouseEnter(index, item.id)}
                      onMouseLeave={handleMouseLeave}
                      className={selectedItem === index ? "selected" : ""}
                      onClick={() => {
                        setSelectedItem(index);
                        handleFilter(item.id);
                      }}
                    >
                      {item.name}
                      {allTypes.some(
                        (type) => type.type_categories_id === item.id
                      ) && (
                        <span
                          style={{
                            marginLeft: "5px",
                            fontSize: "10px",
                            color: "#d9d9d9",
                          }}
                        >
                          {hoveredIndex === index ? "▲" : "▼"}
                        </span>
                      )}
                    </span>
                  ))
                ) : (
                  <p>No available styles</p>
                )}
              </div>
              {showSubMenu && hoveredTypes.length > 0 && (
                <div
                  className="subMenu"
                  onMouseEnter={handleSubMenuMouseEnter}
                  onMouseLeave={handleSubMenuMouseLeave}
                  style={{
                    position: "absolute",
                    top: rowItemRef.current
                      ? rowItemRef.current.offsetTop +
                        rowItemRef.current.offsetHeight
                      : 0,
                    left: rowItemRef.current
                      ? rowItemRef.current.children[
                          hoveredIndex + 1
                        ]?.getBoundingClientRect().left -
                        rowItemRef.current.getBoundingClientRect().left
                      : 0,
                  }}
                >
                  {hoveredTypes.map((subItem, subIndex) => (
                    <span
                      key={subIndex}
                      onClick={() => handleFilter(subItem.braid_type_id)}
                    >
                      {subItem.name}
                    </span>
                  ))}
                </div>
              )}
            </div>

            <div
              className="column"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div className="rowM" style={{ width: "100%" }}>
                {loading ? (
                  <div className="loader"></div>
                ) : filteredBraids.length === 0 ? (
                  <div className="loader"></div> // Spinner while no braids available
                ) : (
                  <BraidsForm
                    allBraids={filteredBraids}
                    onDishClick={handleBraidClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer limit={1} position="top-center" />
    </div>
  );
}

export default MenuCenter;

// import React, { useState, useRef, useEffect, useMemo } from "react";
// import "../../assets/styles/style.css";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { ToastContainer, toast } from "react-toastify";
// import AuthService from "../../services/AuthServices";
// import BraidsForm from "../../components/BraidsForm";

// function MenuCenter(props) {
//   const toastId = useRef(null);
//   const [userId, setUserId] = useState();
//   const [token, setToken] = useState();
//   const [categories, setCategories] = useState([]);
//   const [allTypes, setAllTypes] = useState([]);
//   const [allBraids, setAllBraids] = useState([]);
//   const [filteredBraids, setFilteredBraids] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [showSubMenu, setShowSubMenu] = useState(false);
//   const [hoveredTypes, setHoveredTypes] = useState([]);
//   const [hoveredIndex, setHoveredIndex] = useState(null);
//   const [selectedItem, setSelectedItem] = useState(1000);
//   const rowItemRef = useRef(null);
//   const timerRef = useRef(null);
//   const isSmallScreen = useMediaQuery("(max-width:600px)");

//   const getUserData = () => {
//     const tok = localStorage.getItem("token");
//     const id = localStorage.getItem("id");
//     setToken(tok);
//     setUserId(id);
//   };

//   const fetchData = async (fetchFunction, successCallback, errorMessage) => {
//     try {
//       if (token && userId) {
//         const response = await fetchFunction(userId, token);
//         if (response.data) {
//           successCallback(response.data);
//         } else {
//           toast.error(response.error);
//         }
//       } else {
//         toast.error("Token or User ID is missing");
//       }
//     } catch (error) {
//       toast.error(errorMessage);
//     }
//   };

//   const handleCategories = () =>
//     fetchData(
//       AuthService.getBraidsCategories,
//       setCategories,
//       "Error fetching categories"
//     );
//   const handleTypes = () =>
//     fetchData(AuthService.getAllTypes, setAllTypes, "Error fetching types");
//   const handleBraids = () =>
//     fetchData(AuthService.getAllBraids, setAllBraids, "Error fetching braids");

//   useEffect(() => {
//     getUserData();
//   }, []);

//   useEffect(() => {
//     if (userId && token) {
//       handleCategories();
//       handleTypes();
//       handleBraids();
//     }
//   }, [userId, token]);

//   const handleMouseEnter = (index, categoryId) => {
//     clearTimeout(timerRef.current);
//     setHoveredIndex(index);
//     const filteredTypes = allTypes.filter(
//       (type) => type.type_categories_id === categoryId
//     );
//     setHoveredTypes(filteredTypes);
//     setShowSubMenu(true);
//   };

//   const handleMouseLeave = () => {
//     timerRef.current = setTimeout(() => {
//       setShowSubMenu(false);
//     }, 200);
//   };

//   const handleSubMenuMouseEnter = () => {
//     clearTimeout(timerRef.current);
//     setShowSubMenu(true);
//   };

//   const handleSubMenuMouseLeave = () => {
//     timerRef.current = setTimeout(() => {
//       setShowSubMenu(false);
//     }, 0);
//   };

//   const handleFilter = (idCategorie) => {
//     const filtered = allBraids.filter(
//       (braid) => braid.braid_type_id === idCategorie
//     );
//     setFilteredBraids(filtered);
//   };

//   const handleResetFilter = () => {
//     setFilteredBraids(allBraids);
//   };

//   useEffect(() => {
//     if (allBraids.length > 0) {
//       setFilteredBraids(allBraids);
//     }
//   }, [allBraids]);

//   const handleBraidClick = (id) => {
//     const selected = allBraids.find((braid) => braid.id === id);
//     // Do something with the selected braid if needed
//   };

//   return (
//     <div className={`${isSmallScreen ? "main-contentH1" : "main-contentH"}`}>
//       <div
//         className={`${isSmallScreen ? "column" : "row"}`}
//         style={{ flex: 1 }}
//       >
//         <div
//           className="main-content"
//           style={{ width: isSmallScreen ? "100%" : "100%" }}
//         >
//           <div className="padding">
//             <div className="menuItem">
//               <div className="rowItem itemM" ref={rowItemRef}>
//                 <span
//                   className={selectedItem === 1000 ? "selected" : ""}
//                   onClick={() => {
//                     setSelectedItem(1000);
//                     handleResetFilter();
//                   }}
//                 >
//                   All
//                 </span>
//                 {categories.length > 0 ? (
//                   categories.map((item, index) => (
//                     <span
//                       key={item.id}
//                       onMouseEnter={() => handleMouseEnter(index, item.id)}
//                       onMouseLeave={handleMouseLeave}
//                       className={selectedItem === index ? "selected" : ""}
//                       onClick={() => {
//                         setSelectedItem(index);
//                         handleFilter(item.id);
//                       }}
//                     >
//                       {item.name}
//                       {allTypes.some(
//                         (type) => type.type_categories_id === item.id
//                       ) && (
//                         <span
//                           style={{
//                             marginLeft: "5px",
//                             fontSize: "10px",
//                             color: "#d9d9d9",
//                           }}
//                         >
//                           {hoveredIndex === index ? "▲" : "▼"}
//                         </span>
//                       )}
//                     </span>
//                   ))
//                 ) : (
//                   <p>Aucune catégorie disponible</p>
//                 )}
//               </div>
//               {showSubMenu && hoveredTypes.length > 0 && (
//                 <div
//                   className="subMenu"
//                   onMouseEnter={handleSubMenuMouseEnter}
//                   onMouseLeave={handleSubMenuMouseLeave}
//                   style={{
//                     position: "absolute",
//                     top: rowItemRef.current
//                       ? rowItemRef.current.offsetTop +
//                         rowItemRef.current.offsetHeight
//                       : 0,
//                     left: rowItemRef.current
//                       ? rowItemRef.current.children[
//                           hoveredIndex + 1
//                         ]?.getBoundingClientRect().left -
//                         rowItemRef.current.getBoundingClientRect().left
//                       : 0,
//                   }}
//                 >
//                   {hoveredTypes.map((subItem, subIndex) => (
//                     <span
//                       key={subIndex}
//                       onClick={() => handleFilter(subItem.braid_type_id)}
//                     >
//                       {subItem.name}
//                     </span>
//                   ))}
//                 </div>
//               )}
//             </div>

//             <div
//               className="column"
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 width: "100%",
//               }}
//             >
//               <div className="rowM" style={{ width: "100%" }}>
//                 {loading ? (
//                   <div className="loader"></div>
//                 ) : filteredBraids.length > 0 ? (
//                   <BraidsForm
//                     allBraids={filteredBraids}
//                     onDishClick={handleBraidClick}
//                   />
//                 ) : (
//                   <p>No braids available.</p>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <ToastContainer limit={1} position="top-center" />
//     </div>
//   );
// }

// export default MenuCenter;
