import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import HomeUser from "../pages/HomeUser";
import Braiding from "../pages/Braiding";
import Styles from "../pages/Styles";
import Login from "../pages/Login";
import Booking from "../pages/Booking";
import BookingUser from "../pages/BookingUser";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthService from "../services/AuthServices";
import ResetPassword from "../pages/ResetPassword";
import Order from "../pages/Order";
import OrderForm from "../pages/OrderForm";
import injectColors from "../assets/config/InjectorColor";

function AuthRoute({ children }) {
  return AuthService.isLoggedIn() ? children : <Navigate to="/" replace />;
}

function index() {
  injectColors();
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route
          path="/home/*"
          element={
            <AuthRoute>
              <Home />
            </AuthRoute>
          }
        />
        <Route
          path="/homeUser/*"
          element={
            <AuthRoute>
              <HomeUser />
            </AuthRoute>
          }
        />
        <Route
          path="/menu/*"
          element={
            <AuthRoute>
              <Braiding />
            </AuthRoute>
          }
        />
        <Route
          path="/styles/*"
          element={
            <AuthRoute>
              <Styles />
            </AuthRoute>
          }
        />
        <Route
          path="/table/*"
          element={
            <AuthRoute>
              <Booking />{" "}
            </AuthRoute>
          }
        />
        <Route
          path="/order/*"
          element={
            <AuthRoute>
              <Order />{" "}
            </AuthRoute>
          }
        />
        <Route
          path="/booking/*"
          element={
            <AuthRoute>
              <OrderForm />{" "}
            </AuthRoute>
          }
        />
        <Route
          path="/bookingUser/*"
          element={
            <AuthRoute>
              <BookingUser />{" "}
            </AuthRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default index;
