import React from "react";
import "./tableGenerator.css";
import braid1 from "../../assets/icons/braid1.svg";

function TableGenerator({
  name,
  totalChairs,
  status,
  employer_id,
  braid_id,
  price,
  order_status,
  startTime,
  endTime,
  date,
  isSelected,
  action,
}) {
  const calculateChairsPerSide = () => {
    const sides = { top: 0, left: 0, right: 0, bottom: 0 };
    let remainingChairs = totalChairs;
    if (remainingChairs >= 4) {
      sides.top = Math.floor(remainingChairs / 4);
      sides.left = Math.floor(remainingChairs / 4);
      sides.right = Math.floor(remainingChairs / 4);
      sides.bottom = Math.floor(remainingChairs / 4);
      remainingChairs -= 4 * sides.top;

      while (remainingChairs > 0) {
        if (remainingChairs > 0) {
          sides.top += 1;
          remainingChairs -= 1;
        }
        if (remainingChairs > 0) {
          sides.bottom += 1;
          remainingChairs -= 1;
        }
        if (remainingChairs > 0) {
          sides.left += 1;
          remainingChairs -= 1;
        }
        if (remainingChairs > 0) {
          sides.right += 1;
          remainingChairs -= 1;
        }
      }
    } else if (remainingChairs === 3) {
      sides.top = 1;
      sides.left = 1;
      sides.right = 1;
    } else if (remainingChairs === 2) {
      sides.left = 1;
      sides.right = 1;
    } else if (remainingChairs === 1) {
      sides.top = 1;
    }

    return sides;
  };

  const chairsPerSide = calculateChairsPerSide();

  return (
    <div
      className={`table-card ${isSelected ? "selected" : ""}`}
      onClick={() => action()}
    >
      <h3>{name}</h3>
      <div className="row">
        <div className="chair-container">
          {Array.from({ length: chairsPerSide.top }).map((_, index) => (
            <div
              key={index}
              className="chairW"
              style={{
                width: `${100 / chairsPerSide.top}px`,
                height: "10px",
                marginRight: chairsPerSide.top > 1 ? "5px" : "0",
              }}
            ></div>
          ))}
        </div>
      </div>
      <div className="row">
        <div className="chair-container">
          <div className="column">
            {Array.from({ length: chairsPerSide.left }).map((_, index) => (
              <div key={index}>
                <div
                  className="chairH"
                  style={{
                    width: "10px",
                    height: `${100 / chairsPerSide.left}px`,
                    marginBottom: chairsPerSide.left > 1 ? "5px" : "0",
                  }}
                ></div>
              </div>
            ))}
          </div>
          <div className="table">
            <img src={braid1} alt="" width="100%" height="100%" />
          </div>
          <div className="column">
            {Array.from({ length: chairsPerSide.right }).map((_, index) => (
              <div
                key={index}
                className="chairH"
                style={{
                  width: "10px",
                  height: `${100 / chairsPerSide.right}px`,
                  marginBottom: chairsPerSide.right > 1 ? "5px" : "0",
                }}
              ></div>
            ))}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="chair-container">
          {Array.from({ length: chairsPerSide.bottom }).map((_, index) => (
            <div
              key={index}
              className="chairW"
              style={{
                width: `${100 / chairsPerSide.bottom}px`,
                height: "10px",
                marginRight: chairsPerSide.bottom > 1 ? "5px" : "0",
              }}
            ></div>
          ))}
        </div>
      </div>

      <p className={`status ${status}`}>
        {status === "taken"
          ? "Taken"
          : status === "available"
          ? "Available"
          : "Reserved"}
      </p>

      {/* Additional Information Section */}
      <div className="additional-info">
        <p>Employer ID: {employer_id}</p>
        <p>Braid ID: {braid_id}</p>
        <p>Price: ${price}</p>
        <p>Order Status: {order_status}</p>
        <p>Start Time: {startTime}</p>
        <p>End Time: {endTime}</p>
        <p>Date: {date}</p>
      </div>
    </div>
  );
}

export default TableGenerator;
