import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../assets/styles/style.css";
import img from "../../assets/images/photo.png";
import logoutIcon from "../../assets/icons/logout-icon.svg";
import Order from "../../assets/icons/order";
import AuthService from "../../services/AuthServices";
import Booking from "../../assets/icons/booking";
import { FaHome } from "react-icons/fa";
import Braiding from "../../assets/icons/braiding";
import { colors } from "../../assets/config/color";

const Sidebar = () => {
  const location = useLocation();
  const [activeScreen, setActiveScreen] = useState("/");

  useEffect(() => {
    setActiveScreen(location.pathname);
  }, [location.pathname]);

  const navigate = useNavigate();

  const handleLogout = () => {
    AuthService.logout();
    navigate("/");
  };

  return (
    <div id="sidebar">
      <MenuItem
        path="/homeUser"
        icon={
          <FaHome
            size={20}
            style={{
              color: activeScreen === "/homeUser" ? colors.gold : colors.gris,
            }}
          />
        }
        text="myHome"
        active={activeScreen === "/homeUser"}
      />
      <MenuItem
        path="/styles"
        icon={
          <Braiding
            fill={activeScreen === "/styles" ? colors.gold : colors.gris}
          />
        }
        text="Styles"
        active={activeScreen === "/styles"}
      />
      <MenuItem
        path="/bookingUser"
        icon={
          <Booking
            fill={activeScreen === "/bookingUser" ? colors.gold : colors.gris}
          />
        }
        text="myBookings"
        active={activeScreen === "/bookingUser"}
      />
      <MenuItem
        path="/booking"
        icon={
          <Order
            fill={activeScreen === "/booking" ? colors.gold : colors.gris}
          />
        }
        text="Book"
        active={activeScreen === "/booking"}
      />
      <div style={{ marginTop: "auto" }}>
        <div className="item-menu item-menunotfocus photo">
          <img src={img} alt="" />
        </div>
        <div
          className="item-menu item-menunotfocus logout"
          onClick={handleLogout}
        >
          <img src={logoutIcon} alt="" />
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

const MenuItem = ({ path, icon, text, active }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div
      className={`item-menu ${active ? "item-menufocus" : "item-menunotfocus"}`}
      onClick={() => handleNavigation(path)}
    >
      {icon}
      <span style={{ color: active ? colors.gold : "" }}>{text}</span>
    </div>
  );
};

export default Sidebar;

// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import "../../assets/styles/style.css";
// import img from "../../assets/images/photo.png";
// import logoutIcon from "../../assets/icons/logout-icon.svg";
// import Order from "../../assets/icons/order";
// import AuthService from "../../services/AuthServices";
// import Booking from "../../assets/icons/booking";
// import { FaHome } from "react-icons/fa";
// import Braiding from "../../assets/icons/braiding";
// import { colors } from "../../assets/config/color";

// const Sidebar = () => {
//   const location = useLocation();
//   const [activeScreen, setActiveScreen] = useState("/");

//   useEffect(() => {
//     setActiveScreen(location.pathname);
//   }, [location.pathname]);

//   const navigate = useNavigate();

//   const handleLogout = () => {
//     AuthService.logout();
//     navigate("/");
//   };

//   return (
//     <div id="sidebar">
//       <MenuItem
//         path="/homeUser"
//         icon={
//           <FaHome
//             size={20}
//             style={{
//               color: activeScreen === "/homeUser" ? colors.gold : colors.gris,
//             }}
//           />
//         }
//         text="myHome"
//         active={activeScreen === "/homeUser"}
//       />
//       <MenuItem
//         path="/styles"
//         icon={
//           <Braiding
//             fill={activeScreen === "/styles" ? colors.gold : colors.gris}
//           />
//         }
//         text="Styles"
//         active={activeScreen === "/styles"}
//       />
//       <MenuItem
//         path="/bookingUser"
//         icon={
//           <Booking
//             fill={activeScreen === "/bookingUser" ? colors.gold : colors.gris}
//           />
//         }
//         text="myBookings"
//         active={activeScreen === "/bookingUser"}
//       />
//       <MenuItem
//         path="/booking"
//         icon={
//           <Order
//             fill={activeScreen === "/booking" ? colors.gold : colors.gris}
//           />
//         }
//         text="Book"
//         active={activeScreen === "/booking"}
//       />
//       <div style={{ marginTop: "auto" }}>
//         <div className="item-menu item-menunotfocus photo">
//           <img src={img} alt="" />
//         </div>
//         <div
//           className="item-menu item-menunotfocus logout"
//           onClick={handleLogout}
//         >
//           <img src={logoutIcon} alt="" />
//           <span>Logout</span>
//         </div>
//       </div>
//     </div>
//   );
// };

// const MenuItem = ({ path, icon, text, active }) => {
//   const navigate = useNavigate();

//   const handleNavigation = (path) => {
//     navigate(path);
//   };

//   return (
//     <div
//       className={`item-menu ${active ? "item-menufocus" : "item-menunotfocus"}`}
//       onClick={() => handleNavigation(path)}
//     >
//       {icon}
//       <span style={{ color: active ? colors.gold : "" }}>{text}</span>
//     </div>
//   );
// };

// export default Sidebar;

// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import "../../assets/styles/style.css";
// import img from "../../assets/images/photo.png";
// import logoutIcon from "../../assets/icons/logout-icon.svg";
// import Order from "../../assets/icons/order";
// import AuthService from "../../services/AuthServices";
// import Booking from "../../assets/icons/booking";
// import { FaHome } from "react-icons/fa";
// import Braiding from "../../assets/icons/braiding";
// import { colors } from "../../assets/config/color";
// const Sidebar = () => {
//   const location = useLocation();
//   const [activeScreen, setActiveScreen] = useState("/");
//   useEffect(() => {
//     setActiveScreen(location.pathname);
//   }, [location.pathname]);
//   const navigate = useNavigate();
//   const handleLogout = () => {
//     AuthService.logout();
//     navigate("/");
//   };
//   return (
//     <div id="sidebar">
//       <MenuItem
//         path="/homeUser"
//         icon={
//           <FaHome
//             size={24}
//             style={{
//               color: activeScreen === "/homeUser" ? colors.gold : colors.gris,
//             }}
//           />
//         }
//         text="myHome"
//         active={activeScreen === "/homeUser"}
//       />
//       <MenuItem
//         path="/styles"
//         icon={
//           <Braiding
//             fill={activeScreen === "/styles" ? colors.gold : colors.gris}
//           />
//         }
//         text="Styles"
//         active={activeScreen === "/styles"}
//       />
//       <MenuItem
//         path="/bookingUser"
//         icon={
//           <Booking
//             fill={activeScreen === "/bookingUser" ? colors.gold : colors.gris}
//           />
//         }
//         text="myBookings"
//         active={activeScreen === "/bookingUser"}
//       />
//       <MenuItem
//         path="/booking"
//         icon={
//           <Order
//             fill={activeScreen === "/booking" ? colors.gold : colors.gris}
//           />
//         }
//         text="Book"
//         active={activeScreen === "/booking"}
//       />
//       <div style={{ marginTop: "auto" }}>
//         <div className="item-menu item-menunotfocus photo">
//           <img src={img} alt="" />
//         </div>
//         <div
//           className="item-menu item-menunotfocus logout"
//           onClick={() => handleLogout()}
//         >
//           <img src={logoutIcon} alt="" />
//           <span>Logout</span>
//         </div>
//       </div>
//     </div>
//   );
// };

// const MenuItem = ({ path, icon, text, active }) => {
//   const navigate = useNavigate();
//   const handleNavigation = (path) => {
//     navigate(path);
//   };

//   return (
//     <div
//       className={`item-menu ${
//         active ? "item-menufocus " : "item-menunotfocus "
//       }`}
//       onClick={() => handleNavigation(path)}
//     >
//       {icon}
//       <span style={{ color: active ? colors.gold : "" }}>{text}</span>
//     </div>
//   );
// };

// export default Sidebar;
