import React, { useState, useEffect } from "react";
import AuthService from "../services/AuthServices";
import Header from "../layoutUser/header/header";
import Sidebar from "../layoutUser/sidebar/sidebar";
import Center from "../layoutUser/center/home";
import "../assets/styles/style.css";

const OrderForm = () => {
  const [allTypes, setAllTypes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedBraid, setSelectedBraid] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("09:30");
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const userId = localStorage.getItem("id");
  const BASE_URL = "https://ginasafricanhairbraiding.terangapp.com/api/braids";
  const BASE_URL2 = "https://ginasafricanhairbraiding.terangapp.com/api/users";
  const BASE_URL3 = "https://ginasafricanhairbraiding.terangapp.com/api/order";

  // Fetch braids and employees on mount
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        handleLogout();
        return;
      }

      try {
        const [braids, emps] = await Promise.all([
          AuthService.getAllTypes(userId, token),
          AuthService.getEmployers(token),
        ]);

        if (braids.error) {
          if (braids.error === "Expired Connexion, please reconnect") {
            handleLogout();
            return;
          }
        }

        setAllTypes(Array.isArray(braids.data) ? braids.data : []);
        setEmployees(Array.isArray(emps.data) ? emps.data : []);

        const currentDate = new Date().toISOString().split("T")[0];
        setDate(currentDate);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setMessage("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  // Fetch categories on mount
  useEffect(() => {
    const fetchCategories = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        handleLogout();
        return;
      }

      try {
        const response = await fetch(`${BASE_URL}/getAllCategories`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          throw new Error(
            errorResponse.message || "Failed to fetch categories."
          );
        }

        const data = await response.json();
        setCategories(data.data || []);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
        setMessage("Failed to load categories.");
      }
    };

    fetchCategories();
  }, []);

  // Fetch available employees whenever the date, start time, end time, or selected braid changes
  useEffect(() => {
    const fetchAvailableEmployees = async () => {
      if (!date || !startTime || !endTime) return;

      const token = localStorage.getItem("token");
      const fetchUrl = `${BASE_URL2}/availableEmployees/${date}/${startTime}/${endTime}`;

      try {
        const response = await fetch(fetchUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          throw new Error(
            errorResponse.message || "Failed to fetch employees."
          );
        }

        const availableEmployees = await response.json();
        setEmployees(availableEmployees);
      } catch (error) {
        console.error("Failed to fetch available employees:", error);
        setMessage(error.message || "Failed to load employees.");
      }
    };

    fetchAvailableEmployees();
  }, [date, startTime, endTime, selectedBraid]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    setMessage("Session expired. Please log in again.");
    window.location.href = "/";
  };

  const fetchData = async (url, token) => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error("Error response from server:", errorResponse);
      throw new Error(errorResponse.message || "Failed to fetch data.");
    }

    return await response.json();
  };

  // Validate time format
  const isValidTimeFormat = (time) => {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/; // Matches "HH:mm"
    return regex.test(time);
  };

  const handleBraidChange = async (e) => {
    const braidId = parseInt(e.target.value);
    if (isNaN(braidId)) {
      setPrice(0);
      setSelectedBraid(null);
      return;
    }

    const braid = allTypes.find((b) => b.id === braidId);
    if (!braid) {
      setPrice(0);
      setSelectedBraid(null);
      return;
    }

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("id");

    try {
      const braidData = await fetchData(
        `${BASE_URL}/getBraids/${userId}`,
        token
      );
      const fullBraid = braidData.data.find((b) => b.id === braidId);

      if (fullBraid) {
        const braidDuration = parseTimeToHours(fullBraid.time);
        console.log("Braid Duration (in hours):", braidDuration);
        setSelectedBraid(fullBraid);
        setPrice(fullBraid.priceNormal || 0);

        // Fetch latest end time for the selected braid and today's date
        const today = new Date().toISOString().split("T")[0];
        const response = await fetch(
          `${BASE_URL2}/latestEndTime/${braidId}/${today}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        let newStartTime = "09:00"; // Default value
        if (response.ok) {
          const { latestEndTime } = await response.json();
          const formattedEndTime = latestEndTime
            .split(":")
            .slice(0, 2)
            .join(":"); // Extract "HH:mm"

          // Use latestEndTime as startTime if valid
          if (isValidTimeFormat(formattedEndTime)) {
            newStartTime = formattedEndTime;
          } else {
            console.warn("Invalid time format, reverting to default.");
          }
        }

        // Update start time
        setStartTime(newStartTime);

        // Calculate end time based on the new start time and braid duration
        const calculatedEndTime = calculateEndTimeWithHours(
          newStartTime, // Use updated startTime
          braidDuration
        );
        console.log("Calculated End Time:", calculatedEndTime);

        setEndTime(calculatedEndTime);
      }
    } catch (error) {
      console.error("Failed to fetch braid details:", error);
      setMessage("Failed to load braid details.");
    }
  };

  // Function to parse time from "HH:mm" format to hours
  const parseTimeToHours = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours + minutes / 60; // Convert to hours
  };

  // Function to calculate the end time given start time and duration in hours
  const calculateEndTimeWithHours = (startTime, durationInHours) => {
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const startDate = new Date();
    startDate.setHours(startHours);
    startDate.setMinutes(startMinutes);

    // Add duration
    const endDate = new Date(
      startDate.getTime() + durationInHours * 60 * 60 * 1000
    );

    // Format end time as "HH:mm"
    const endHours = String(endDate.getHours()).padStart(2, "0");
    const endMinutes = String(endDate.getMinutes()).padStart(2, "0");

    console.log("Start Time:", startTime); // Update log
    console.log("End Time:", `${endHours}:${endMinutes}`); // Update log

    return `${endHours}:${endMinutes}`;
  };

  const handleEmployeeChange = (e) => {
    setSelectedEmployee(e.target.value);
  };

  const handleTimeChange = (e) => {
    const newStartTime = e.target.value;
    setStartTime(newStartTime);
    const end = calculateEndTimeWithHours(
      newStartTime,
      selectedBraid?.time || 0
    );
    setEndTime(end);
  };

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setDate(newDate);

    // Reset start time and end time
    setStartTime("09:00");
    setEndTime("09:30");

    // Trigger employee fetch (useEffect will handle this)
    // This is automatically managed since you are already listening for date changes in the useEffect
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !selectedBraid ||
      !selectedEmployee ||
      !date ||
      !startTime ||
      !endTime
    ) {
      setMessage("Please fill in all fields.");
      return;
    }

    const orderData = [
      {
        braid_id: selectedBraid.id,
        employer_id: selectedBraid.employer_id,
        client_id: userId,
        quantity,
        price: price || selectedBraid.priceNormal,
        date,
        startTime,
        endTime,
      },
    ];
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${BASE_URL3}/addOrders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Add token here
        },
        body: JSON.stringify(orderData),
      });

      console.log("Response:", response);

      if (!response.ok) {
        const errorResponse = await response.json();
        if (errorResponse.error === "Expired Connexion, please reconnect") {
          handleLogout();
          return;
        }
        throw new Error(errorResponse.message || "Failed to place order.");
      }

      setMessage("Order placed successfully!");
      resetForm();
      setTimeout(() => setMessage(""), 3000);
    } catch (error) {
      console.error(error);
      setMessage(error.message || "Failed to place order.");
    }
  };

  const resetForm = () => {
    setSelectedBraid(null);
    setSelectedEmployee(null);
    setDate(new Date().toISOString().split("T")[0]);
    setStartTime("09:00");
    setEndTime("09:30");
    setQuantity(1);
    setPrice(0);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <Header />
      <Sidebar />
      <div className="order-form">
        <h2>Place an Order</h2>
        <div className="form-scroll">
          <form onSubmit={handleSubmit} style={{ marginBottom: "20px" }}>
            <label>
              Select Braid Type:
              <select
                onChange={handleBraidChange}
                value={selectedBraid?.id || ""}
              >
                <option value="">Select...</option>
                {allTypes.length > 0 ? (
                  allTypes.map((braid) => {
                    const category = categories.find(
                      (cat) => cat.id === braid.type_categories_id
                    );
                    const categoryName = category
                      ? category.name
                      : "Unknown Category";
                    return (
                      <option key={braid.id} value={braid.id}>
                        {braid.name} ({categoryName})
                      </option>
                    );
                  })
                ) : (
                  <option disabled>No braid types available</option>
                )}
              </select>
            </label>

            <label>
              Select Employee:
              <select
                onChange={handleEmployeeChange}
                value={selectedEmployee || ""}
              >
                <option value="">Select...</option>
                {employees.length > 0 ? (
                  employees.map((emp) => (
                    <option key={emp.id} value={emp.id}>
                      {emp.firstName} {emp.lastName}
                    </option>
                  ))
                ) : (
                  <option disabled>No employees available</option>
                )}
              </select>
            </label>

            <label>
              Date:
              <input
                type="date"
                onChange={handleDateChange} // Updated to use the new handler
                value={date}
                min={new Date().toISOString().split("T")[0]}
              />
            </label>

            <label>
              Start Time:
              <input
                type="time"
                onChange={handleTimeChange}
                value={startTime}
                min="09:00"
                max="21:00"
              />
            </label>

            <label>
              End Time:
              <input type="time" value={endTime} readOnly />
            </label>

            <label>
              Quantity:
              <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                min="1"
                disabled
              />
            </label>

            <label>
              Price:
              <input
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                min="0"
                readOnly
                disabled
              />
            </label>

            <button type="submit">Place Order</button>

            <div className="categories">
              {categories.length > 0 && (
                <div>
                  <h3>Available Categories</h3>
                  <ul>
                    {categories.map((category) => (
                      <li key={category.id}>
                        {category.name}: {category.description}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {message && <div className="message">{message}</div>}
          </form>
        </div>
      </div>
      <Center />
    </div>
  );
};

export default OrderForm;

// import React, { useState, useEffect } from "react";
// import AuthService from "../services/AuthServices";
// import Header from "../layout/header/header";
// import Sidebar from "../layout/sidebar/sidebar";
// import Center from "../layout/center/home";
// import "../assets/styles/style.css";

// const OrderForm = () => {
//   const [allTypes, setAllTypes] = useState([]);
//   const [employees, setEmployees] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [selectedBraid, setSelectedBraid] = useState(null);
//   const [selectedEmployee, setSelectedEmployee] = useState(null);
//   const [date, setDate] = useState("");
//   const [startTime, setStartTime] = useState("09:00");
//   const [endTime, setEndTime] = useState("09:30");
//   const [quantity, setQuantity] = useState(1);
//   const [price, setPrice] = useState(0);
//   const [message, setMessage] = useState("");
//   const [loading, setLoading] = useState(true);
//   const userId = localStorage.getItem("id");
//   const BASE_URL = "https://ginasafricanhairbraiding.terangapp.com/api/braids";
//   const BASE_URL2 = "https://ginasafricanhairbraiding.terangapp.com/api/users";

//   // Fetch braids and employees on mount
//   useEffect(() => {
//     const fetchData = async () => {
//       const token = localStorage.getItem("token");

//       if (!token) {
//         handleLogout();
//         return;
//       }

//       try {
//         const [braids, emps] = await Promise.all([
//           AuthService.getAllTypes(userId, token),
//           AuthService.getEmployers(token),
//         ]);

//         if (braids.error) {
//           if (braids.error === "Expired Connexion, please reconnect") {
//             handleLogout();
//             return;
//           }
//         }

//         setAllTypes(Array.isArray(braids.data) ? braids.data : []);
//         setEmployees(Array.isArray(emps.data) ? emps.data : []);

//         const currentDate = new Date().toISOString().split("T")[0];
//         setDate(currentDate);
//       } catch (error) {
//         console.error("Failed to fetch data:", error);
//         setMessage("Failed to load data.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [userId]);

//   // Fetch categories on mount
//   useEffect(() => {
//     const fetchCategories = async () => {
//       const token = localStorage.getItem("token");

//       if (!token) {
//         handleLogout();
//         return;
//       }

//       try {
//         const response = await fetch(`${BASE_URL}/getAllCategories`, {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (!response.ok) {
//           const errorResponse = await response.json();
//           throw new Error(
//             errorResponse.message || "Failed to fetch categories."
//           );
//         }

//         const data = await response.json();
//         setCategories(data.data || []);
//       } catch (error) {
//         console.error("Failed to fetch categories:", error);
//         setMessage("Failed to load categories.");
//       }
//     };

//     fetchCategories();
//   }, []);

//   // Fetch available employees whenever the date, start time, end time, or selected braid changes
//   useEffect(() => {
//     const fetchAvailableEmployees = async () => {
//       if (!date || !startTime || !endTime) return;

//       const token = localStorage.getItem("token");
//       const fetchUrl = `${BASE_URL2}/availableEmployees/${date}/${startTime}/${endTime}`;

//       try {
//         const response = await fetch(fetchUrl, {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (!response.ok) {
//           const errorResponse = await response.json();
//           throw new Error(
//             errorResponse.message || "Failed to fetch employees."
//           );
//         }

//         const availableEmployees = await response.json();
//         setEmployees(availableEmployees);
//       } catch (error) {
//         console.error("Failed to fetch available employees:", error);
//         setMessage(error.message || "Failed to load employees.");
//       }
//     };

//     fetchAvailableEmployees();
//   }, [date, startTime, endTime, selectedBraid]);

//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("id");
//     setMessage("Session expired. Please log in again.");
//     window.location.href = "/login";
//   };

//   const fetchData = async (url, token) => {
//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     if (!response.ok) {
//       const errorResponse = await response.json();
//       console.error("Error response from server:", errorResponse);
//       throw new Error(errorResponse.message || "Failed to fetch data.");
//     }

//     return await response.json();
//   };

//   // Validate time format
//   const isValidTimeFormat = (time) => {
//     const regex = /^([01]\d|2[0-3]):([0-5]\d)$/; // Matches "HH:mm"
//     return regex.test(time);
//   };

//   const handleBraidChange = async (e) => {
//     const braidId = parseInt(e.target.value);
//     if (isNaN(braidId)) {
//       setPrice(0);
//       setSelectedBraid(null);
//       return;
//     }

//     const braid = allTypes.find((b) => b.id === braidId);
//     if (!braid) {
//       setPrice(0);
//       setSelectedBraid(null);
//       return;
//     }

//     const token = localStorage.getItem("token");
//     const userId = localStorage.getItem("id");

//     try {
//       const braidData = await fetchData(
//         `${BASE_URL}/getBraids/${userId}`,
//         token
//       );
//       const fullBraid = braidData.data.find((b) => b.id === braidId);

//       if (fullBraid) {
//         const braidDuration = parseTimeToHours(fullBraid.time);
//         console.log("Braid Duration (in hours):", braidDuration);
//         setSelectedBraid(fullBraid);
//         setPrice(fullBraid.priceNormal || 0);

//         // Fetch latest end time for the selected braid and today's date
//         const today = new Date().toISOString().split("T")[0];
//         const response = await fetch(
//           `${BASE_URL2}/latestEndTime/${braidId}/${today}`,
//           {
//             method: "GET",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         let newStartTime = "09:00"; // Default value
//         if (response.ok) {
//           const { latestEndTime } = await response.json();
//           const formattedEndTime = latestEndTime
//             .split(":")
//             .slice(0, 2)
//             .join(":"); // Extract "HH:mm"

//           // Use latestEndTime as startTime if valid
//           if (isValidTimeFormat(formattedEndTime)) {
//             newStartTime = formattedEndTime;
//           } else {
//             console.warn("Invalid time format, reverting to default.");
//           }
//         }

//         // Update start time
//         setStartTime(newStartTime);

//         // Calculate end time based on the new start time and braid duration
//         const calculatedEndTime = calculateEndTimeWithHours(
//           newStartTime, // Use updated startTime
//           braidDuration
//         );
//         console.log("Calculated End Time:", calculatedEndTime);

//         setEndTime(calculatedEndTime);
//       }
//     } catch (error) {
//       console.error("Failed to fetch braid details:", error);
//       setMessage("Failed to load braid details.");
//     }
//   };

//   // Function to parse time from "HH:mm" format to hours
//   const parseTimeToHours = (timeString) => {
//     const [hours, minutes] = timeString.split(":").map(Number);
//     return hours + minutes / 60; // Convert to hours
//   };

//   // Function to calculate the end time given start time and duration in hours
//   const calculateEndTimeWithHours = (startTime, durationInHours) => {
//     const [startHours, startMinutes] = startTime.split(":").map(Number);
//     const startDate = new Date();
//     startDate.setHours(startHours);
//     startDate.setMinutes(startMinutes);

//     // Add duration
//     const endDate = new Date(
//       startDate.getTime() + durationInHours * 60 * 60 * 1000
//     );

//     // Format end time as "HH:mm"
//     const endHours = String(endDate.getHours()).padStart(2, "0");
//     const endMinutes = String(endDate.getMinutes()).padStart(2, "0");

//     console.log("Start Time:", startTime); // Update log
//     console.log("End Time:", `${endHours}:${endMinutes}`); // Update log

//     return `${endHours}:${endMinutes}`;
//   };

//   const handleEmployeeChange = (e) => {
//     setSelectedEmployee(e.target.value);
//   };

//   const handleTimeChange = (e) => {
//     const newStartTime = e.target.value;
//     setStartTime(newStartTime);
//     const end = calculateEndTimeWithHours(
//       newStartTime,
//       selectedBraid?.time || 0
//     );
//     setEndTime(end);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (
//       !selectedBraid ||
//       !selectedEmployee ||
//       !date ||
//       !startTime ||
//       !endTime
//     ) {
//       setMessage("Please fill in all fields.");
//       return;
//     }

//     const orderData = {
//       braid_id: selectedBraid.id,
//       employer_id: selectedBraid.employer_id,
//       client_id: userId,
//       quantity,
//       price: price || selectedBraid.priceNormal,
//       date,
//       startTime,
//       endTime,
//     };

//     try {
//       const response = await fetch("/api/orders/addOrders", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(orderData),
//       });

//       if (!response.ok) {
//         const errorResponse = await response.json();
//         if (errorResponse.error === "Expired Connexion, please reconnect") {
//           handleLogout();
//           return;
//         }
//         throw new Error(errorResponse.message || "Failed to place order.");
//       }

//       setMessage("Order placed successfully!");
//       resetForm();
//       setTimeout(() => setMessage(""), 3000);
//     } catch (error) {
//       console.error(error);
//       setMessage(error.message || "Failed to place order.");
//     }
//   };

//   const resetForm = () => {
//     setSelectedBraid(null);
//     setSelectedEmployee(null);
//     setDate(new Date().toISOString().split("T")[0]);
//     setStartTime("09:00");
//     setEndTime("09:30");
//     setQuantity(1);
//     setPrice(0);
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="container">
//       <Header />
//       <Sidebar />
//       <div className="order-form">
//         <h2>Place an Order</h2>
//         <div className="form-scroll">
//           <form onSubmit={handleSubmit} style={{ marginBottom: "20px" }}>
//             <label>
//               Select Braid Type:
//               <select
//                 onChange={handleBraidChange}
//                 value={selectedBraid?.id || ""}
//               >
//                 <option value="">Select...</option>
//                 {allTypes.length > 0 ? (
//                   allTypes.map((braid) => {
//                     const category = categories.find(
//                       (cat) => cat.id === braid.type_categories_id
//                     );
//                     const categoryName = category
//                       ? category.name
//                       : "Unknown Category";
//                     return (
//                       <option key={braid.id} value={braid.id}>
//                         {braid.name} ({categoryName})
//                       </option>
//                     );
//                   })
//                 ) : (
//                   <option disabled>No braid types available</option>
//                 )}
//               </select>
//             </label>

//             <label>
//               Select Employee:
//               <select
//                 onChange={handleEmployeeChange}
//                 value={selectedEmployee || ""}
//               >
//                 <option value="">Select...</option>
//                 {employees.length > 0 ? (
//                   employees.map((emp) => (
//                     <option key={emp.id} value={emp.id}>
//                       {emp.firstName} {emp.lastName}
//                     </option>
//                   ))
//                 ) : (
//                   <option disabled>No employees available</option>
//                 )}
//               </select>
//             </label>

//             <label>
//               Date:
//               <input
//                 type="date"
//                 onChange={(e) => setDate(e.target.value)}
//                 value={date}
//                 min={new Date().toISOString().split("T")[0]}
//               />
//             </label>

//             <label>
//               Start Time:
//               <input
//                 type="time"
//                 onChange={handleTimeChange}
//                 value={startTime}
//                 min="09:00"
//                 max="21:00"
//               />
//             </label>

//             <label>
//               End Time:
//               <input type="time" value={endTime} readOnly />
//             </label>

//             <label>
//               Quantity:
//               <input
//                 type="number"
//                 value={quantity}
//                 onChange={(e) => setQuantity(e.target.value)}
//                 min="1"
//                 disabled
//               />
//             </label>

//             <label>
//               Price:
//               <input
//                 type="number"
//                 value={price}
//                 onChange={(e) => setPrice(e.target.value)}
//                 min="0"
//                 readOnly
//                 disabled
//               />
//             </label>

//             <button type="submit">Place Order</button>

//             <div className="categories">
//               {categories.length > 0 && (
//                 <div>
//                   <h3>Available Categories</h3>
//                   <ul>
//                     {categories.map((category) => (
//                       <li key={category.id}>
//                         {category.name}: {category.description}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>

//             {message && <div className="message">{message}</div>}
//           </form>
//         </div>
//       </div>
//       <Center />
//     </div>
//   );
// };

// export default OrderForm;

// import React, { useState, useEffect } from "react";
// import AuthService from "../services/AuthServices";
// import Header from "../layout/header/header";
// import Sidebar from "../layout/sidebar/sidebar";
// import Center from "../layout/center/home";
// import "../assets/styles/style.css";

// const OrderForm = () => {
//   const [allTypes, setAllTypes] = useState([]);
//   const [employees, setEmployees] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [selectedBraid, setSelectedBraid] = useState(null);
//   const [selectedEmployee, setSelectedEmployee] = useState(null);
//   const [date, setDate] = useState("");
//   const [startTime, setStartTime] = useState("09:00");
//   const [endTime, setEndTime] = useState("09:30");
//   const [quantity, setQuantity] = useState(1);
//   const [price, setPrice] = useState(0);
//   const [message, setMessage] = useState("");
//   const [loading, setLoading] = useState(true);
//   const userId = localStorage.getItem("id");
//   const BASE_URL = "https://ginasafricanhairbraiding.terangapp.com/api/braids";
//   const BASE_URL2 = "https://ginasafricanhairbraiding.terangapp.com/api/users";

//   // Fetch braids and employees on mount
//   useEffect(() => {
//     const fetchData = async () => {
//       const token = localStorage.getItem("token");

//       if (!token) {
//         handleLogout();
//         return;
//       }

//       try {
//         const [braids, emps] = await Promise.all([
//           AuthService.getAllTypes(userId, token),
//           AuthService.getEmployers(token),
//         ]);

//         if (braids.error) {
//           if (braids.error === "Expired Connexion, please reconnect") {
//             handleLogout();
//             return;
//           }
//         }

//         setAllTypes(Array.isArray(braids.data) ? braids.data : []);
//         setEmployees(Array.isArray(emps.data) ? emps.data : []);

//         const currentDate = new Date().toISOString().split("T")[0];
//         setDate(currentDate);
//       } catch (error) {
//         console.error("Failed to fetch data:", error);
//         setMessage("Failed to load data.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [userId]);

//   // Fetch categories on mount
//   useEffect(() => {
//     const fetchCategories = async () => {
//       const token = localStorage.getItem("token");

//       if (!token) {
//         handleLogout();
//         return;
//       }

//       try {
//         const response = await fetch(`${BASE_URL}/getAllCategories`, {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (!response.ok) {
//           const errorResponse = await response.json();
//           throw new Error(
//             errorResponse.message || "Failed to fetch categories."
//           );
//         }

//         const data = await response.json();
//         setCategories(data.data || []);
//       } catch (error) {
//         console.error("Failed to fetch categories:", error);
//         setMessage("Failed to load categories.");
//       }
//     };

//     fetchCategories();
//   }, []);

//   // Fetch available employees whenever the date, start time, end time, or selected braid changes
//   useEffect(() => {
//     const fetchAvailableEmployees = async () => {
//       if (!date || !startTime || !endTime) return;

//       const token = localStorage.getItem("token");
//       const fetchUrl = `${BASE_URL2}/availableEmployees/${date}/${startTime}/${endTime}`;

//       try {
//         const response = await fetch(fetchUrl, {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (!response.ok) {
//           const errorResponse = await response.json();
//           throw new Error(
//             errorResponse.message || "Failed to fetch employees."
//           );
//         }

//         const availableEmployees = await response.json();
//         setEmployees(availableEmployees);
//       } catch (error) {
//         console.error("Failed to fetch available employees:", error);
//         setMessage(error.message || "Failed to load employees.");
//       }
//     };

//     fetchAvailableEmployees();
//   }, [date, startTime, endTime, selectedBraid]);

//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("id");
//     setMessage("Session expired. Please log in again.");
//     window.location.href = "/login";
//   };

//   const fetchData = async (url, token) => {
//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     if (!response.ok) {
//       const errorResponse = await response.json();
//       console.error("Error response from server:", errorResponse);
//       throw new Error(errorResponse.message || "Failed to fetch data.");
//     }

//     return await response.json();
//   };

//   const handleBraidChange = async (e) => {
//     const braidId = parseInt(e.target.value);
//     if (isNaN(braidId)) {
//       setPrice(0);
//       setSelectedBraid(null);
//       return;
//     }

//     const braid = allTypes.find((b) => b.id === braidId);
//     if (!braid) {
//       setPrice(0);
//       setSelectedBraid(null);
//       return;
//     }

//     const token = localStorage.getItem("token");
//     const userId = localStorage.getItem("id");

//     try {
//       const braidData = await fetchData(
//         `${BASE_URL}/getBraids/${userId}`,
//         token
//       );
//       const fullBraid = braidData.data.find((b) => b.id === braidId);

//       if (fullBraid) {
//         const braidTime = parseTimeToHours(fullBraid.time) || 0;
//         setSelectedBraid(fullBraid);
//         setPrice(fullBraid.priceNormal || 0);
//         setStartTime("09:00");
//         setEndTime(calculateEndTimeWithHours("09:00", braidTime));
//       }
//     } catch (error) {
//       console.error("Failed to fetch braid details:", error);
//       setMessage("Failed to load braid details.");
//     }
//   };

//   const parseTimeToHours = (timeString) => {
//     if (!timeString) return 0;
//     const [hours, minutes] = timeString.split(":").map(Number);
//     return hours + minutes / 60;
//   };

//   const calculateEndTimeWithHours = (startTime, hours) => {
//     const start = new Date(`1970-01-01T${startTime}:00`);
//     start.setHours(start.getHours() + hours);
//     return start.toTimeString().split(" ")[0];
//   };

//   const handleEmployeeChange = (e) => {
//     setSelectedEmployee(e.target.value);
//   };

//   const handleTimeChange = (e) => {
//     const newStartTime = e.target.value;
//     setStartTime(newStartTime);
//     const end = calculateEndTimeWithHours(
//       newStartTime,
//       selectedBraid?.time || 0
//     );
//     setEndTime(end);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (
//       !selectedBraid ||
//       !selectedEmployee ||
//       !date ||
//       !startTime ||
//       !endTime
//     ) {
//       setMessage("Please fill in all fields.");
//       return;
//     }

//     const orderData = {
//       braid_id: selectedBraid.id,
//       employer_id: selectedBraid.employer_id,
//       client_id: userId,
//       quantity,
//       price: price || selectedBraid.priceNormal,
//       date,
//       startTime,
//       endTime,
//     };

//     try {
//       const response = await fetch("/api/orders/addOrders", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(orderData),
//       });

//       if (!response.ok) {
//         const errorResponse = await response.json();
//         if (errorResponse.error === "Expired Connexion, please reconnect") {
//           handleLogout();
//           return;
//         }
//         throw new Error(errorResponse.message || "Failed to place order.");
//       }

//       setMessage("Order placed successfully!");
//       resetForm();
//       setTimeout(() => setMessage(""), 3000);
//     } catch (error) {
//       console.error(error);
//       setMessage(error.message || "Failed to place order.");
//     }
//   };

//   const resetForm = () => {
//     setSelectedBraid(null);
//     setSelectedEmployee(null);
//     setDate(new Date().toISOString().split("T")[0]);
//     setStartTime("09:00");
//     setEndTime("09:30");
//     setQuantity(1);
//     setPrice(0);
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="container">
//       <Header />
//       <Sidebar />
//       <div className="order-form">
//         <h2>Place an Order</h2>
//         <div className="form-scroll">
//           <form onSubmit={handleSubmit} style={{ marginBottom: "20px" }}>
//             <label>
//               Select Braid Type:
//               <select
//                 onChange={handleBraidChange}
//                 value={selectedBraid?.id || ""}
//               >
//                 <option value="">Select...</option>
//                 {allTypes.length > 0 ? (
//                   allTypes.map((braid) => {
//                     const category = categories.find(
//                       (cat) => cat.id === braid.type_categories_id
//                     );
//                     const categoryName = category
//                       ? category.name
//                       : "Unknown Category";
//                     return (
//                       <option key={braid.id} value={braid.id}>
//                         {braid.name} ({categoryName})
//                       </option>
//                     );
//                   })
//                 ) : (
//                   <option disabled>No braid types available</option>
//                 )}
//               </select>
//             </label>

//             <label>
//               Select Employee:
//               <select
//                 onChange={handleEmployeeChange}
//                 value={selectedEmployee || ""}
//               >
//                 <option value="">Select...</option>
//                 {employees.length > 0 ? (
//                   employees.map((emp) => (
//                     <option key={emp.id} value={emp.id}>
//                       {emp.firstName} {emp.lastName}
//                     </option>
//                   ))
//                 ) : (
//                   <option disabled>No employees available</option>
//                 )}
//               </select>
//             </label>

//             <label>
//               Date:
//               <input
//                 type="date"
//                 onChange={(e) => setDate(e.target.value)}
//                 value={date}
//                 min={new Date().toISOString().split("T")[0]}
//               />
//             </label>

//             <label>
//               Start Time:
//               <input
//                 type="time"
//                 onChange={handleTimeChange}
//                 value={startTime}
//                 min="09:00"
//                 max="21:00"
//               />
//             </label>

//             <label>
//               End Time:
//               <input type="time" value={endTime} readOnly />
//             </label>

//             <label>
//               Quantity:
//               <input
//                 type="number"
//                 value={quantity}
//                 onChange={(e) => setQuantity(e.target.value)}
//                 min="1"
//                 disabled
//               />
//             </label>

//             <label>
//               Price:
//               <input
//                 type="number"
//                 value={price}
//                 onChange={(e) => setPrice(e.target.value)}
//                 min="0"
//                 readOnly
//                 disabled
//               />
//             </label>

//             <button type="submit">Place Order</button>

//             <div className="categories">
//               {categories.length > 0 && (
//                 <div>
//                   <h3>Available Categories</h3>
//                   <ul>
//                     {categories.map((category) => (
//                       <li key={category.id}>
//                         {category.name}: {category.description}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>

//             {message && <div className="message">{message}</div>}
//           </form>
//         </div>
//       </div>
//       <Center />
//     </div>
//   );
// };

// export default OrderForm;
