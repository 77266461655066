import React, { useState, useEffect } from "react";
import "../../assets/styles/style.css";
import logo from "../../assets/logos/log.jpg";
import refreshIcon from "../../assets/icons/refresh-icon.svg";
import searchIcon from "../../assets/icons/search-icon.svg";
import menuIcon from "../../assets/icons/menu.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

const Header = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [role, setRole] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    const userRole = localStorage.getItem("Role");
    setRole(userRole);
  }, []);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <div className="header">
      <div className="logo">
        <img src={logo} alt="Logo" width={130} height="50px" />
      </div>

      <div className={`search-bar ${isFocused ? "focused" : ""}`}>
        <input
          type="text"
          placeholder="Braiding..."
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          style={{ fontSize: isSmallScreen ? "11px" : "16px" }}
        />
        <img src={searchIcon} alt="Search" />
      </div>

      {isSmallScreen ? (
        <div className="menu-icon" onClick={toggleMenu}>
          <img src={menuIcon} alt="Menu" width={30} />
        </div>
      ) : (
        <div className="icon">
          <div className="icons">
            <img
              src={refreshIcon}
              alt="Refresh"
              width={isSmallScreen ? 20 : 28}
              onClick={handleRefresh}
            />
          </div>
        </div>
      )}

      {isMenuOpen && isSmallScreen && (
        <div className="expandable-menu">
          <div className="menu-items">
            <div className="icon" onClick={handleRefresh}>
              <img src={refreshIcon} alt="Refresh" width={20} />
            </div>
          </div>
          <div className="txtLoger">
            {role && (
              <h3 style={{ fontSize: "16px", textAlign: "center" }}>
                {role.charAt(0).toUpperCase() + role.slice(1)}
              </h3>
            )}
          </div>
        </div>
      )}

      {!isSmallScreen && (
        <div className="txtLoger">
          {role && (
            <h3 style={{ fontSize: "22px" }}>
              {role.charAt(0).toUpperCase() + role.slice(1)}
            </h3>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;

// import React,{useState,useEffect} from "react";
// import "../../assets/styles/style.css";
// import logo from "../../assets/logos/log.jpg";
// import refreshIcon from "../../assets/icons/refresh-icon.svg"
// import searchIcon from "../../assets/icons/search-icon.svg"
// import helpIcon from "../../assets/icons/help-icon.svg"
// import useMediaQuery from '@mui/material/useMediaQuery';
// const Header = () => {
//   const [isFocused, setIsFocused] = useState(false);
//   const [role, setRole]=useState();

//   const isSmallScreen = useMediaQuery('(max-width:600px)');
//   const handleInputFocus = () => {
//     setIsFocused(true);
//   };

//   const handleInputBlur = () => {
//     setIsFocused(false);
//   };
//   const handleRefresh = () => {
//     window.location.reload();
//   };
//   const getUserData =()=>{
//     setRole(localStorage.getItem('Role'));
//   }
//   useEffect(() => {
//     getUserData();
//     console.log(role);
//   }, []);
//   return (
//     <div className="header">
//       <div className="logo">
//         <img src={logo} alt="" width={130} height='50px'  />
//       </div>
//       <div className={`search-bar ${isFocused ? 'focused' : ''}`}>
//         <input type="text" placeholder="Search braiding..."
//         onFocus={handleInputFocus}
//         onBlur={handleInputBlur}
//         style={{ fontSize: isSmallScreen ? '11px' : '16px' }}
//         />
//         <img src={searchIcon} alt="" />
//       </div>
//       <div className="icon">
//         <div className="icons">
//           <img src={refreshIcon} alt="" width={isSmallScreen ? 20 : 28} onClick={handleRefresh}/>
//         </div>
//         <div className="icons" >
//         <img src={helpIcon} alt="" width={isSmallScreen ? 20 : 28} />
//         </div>
//       </div>

//       <div className="txtLoger">
//         {
//           role && (
//             <h3 style={{ fontSize: isSmallScreen ? '16px' : '22px' }}>{ role.charAt(0).toUpperCase() + role.slice(1)}</h3>
//           )
//         }

//       </div>
//     </div>
//   );
// };

// export default Header;
