import React from "react";
import Header from "../layoutUser/header/header";
import Sidebar from "../layoutUser/sidebar/sidebar";
import StylesCenter from "../layoutUser/center/braiding";
import "../assets/styles/style.css";
function Styles() {
  return (
    <div className="container">
      <Header />
      <Sidebar />
      <StylesCenter />
    </div>
  );
}

export default Styles;
