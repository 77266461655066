import React from "react";
import Header from "../layoutUser/header/header";
import Sidebar from "../layoutUser/sidebar/sidebar";
import Center from "../layoutUser/center/home";
import "../assets/styles/style.css";
function HomeUser(props) {
  return (
    <div className="container">
      <Header />
      <Sidebar />
      <Center />
    </div>
  );
}

export default HomeUser;
