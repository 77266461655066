import React, { useState, useEffect, useRef } from "react";
import "../../assets/styles/style.css";
import braid from "../../assets/icons/braid.svg";
import like from "../../assets/icons/like.svg";
import braid1 from "../../assets/icons/braid1.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ToastContainer, toast } from "react-toastify";
import AuthService from "../../services/AuthServices";
import { FaUser } from "react-icons/fa";

function Center(props) {
  const isSmallScreen = useMediaQuery("(max-width:700px)");
  const [userId, setUserId] = useState();
  const [token, setToken] = useState();
  const [employer, setEmployer] = useState([]);
  const toastId = useRef(null);
  const [roles, setRoles] = useState([]);
  const [tronkedEmployer, setTronkedEmployer] = useState([]);
  const [totalBraid, setTotalBraids] = useState(0);

  const getUserData = () => {
    const tok = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    setToken(tok);
    setUserId(id);
  };
  const handleGetEmployer = async () => {
    try {
      if (token && userId) {
        const response = await AuthService.getAllUsers(token);
        if (response.data) {
          setEmployer(response.data);
          setTronkedEmployer(
            response.data.filter((item) => item.id !== +userId).slice(0, 2)
          );
        } else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(response.error);
          }
        }
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Token ou User ID manquant");
        }
      }
    } catch (error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Une erreur s'est produite lors de la soumission du formulaire"
        );
      }
    }
  };

  useEffect(() => {
    getUserData();
  }, []);
  useEffect(() => {
    if (token) {
      handleGetEmployer();
      handleGetRoles();
      handleGetPayment();
    }
  }, [userId, token]);
  const handleGetRoles = async () => {
    try {
      if (token && userId) {
        const response = await AuthService.getRoles(userId, token);
        if (response.data) {
          setRoles(response.data);
        } else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(response.error);
          }
        }
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Token ou User ID manquant");
        }
      }
    } catch (error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Une erreur s'est produite lors de la soumission du formulaire"
        );
      }
    }
  };

  const [showAllEmployer, setShowAllEmployer] = useState(false);
  const handleViewAllEmployer = () => {
    setShowAllEmployer(true);
    setTronkedEmployer(employer.filter((item) => item.id !== +userId));
  };
  const handleViewLessEmployer = () => {
    setShowAllEmployer(false);
    setTronkedEmployer(
      employer.filter((item) => item.id !== +userId).slice(0, 2)
    );
  };
  const handleGetPayment = async () => {
    try {
      if (token) {
        const response = await AuthService.getStatistique(token);
        if (response) {
          setTotalBraids(response.braid);
        }
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Token ou User ID manquant");
        }
      }
    } catch (error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "An error occurred while submitting the form"
        );
      }
    }
  };
  return (
    <div className={`${isSmallScreen ? "main-contentHR" : "main-contentH"}`}>
      <div className={`${isSmallScreen ? "column" : "row"}`}>
        <div className="main-contentH1">
          <div className="padding">
            <div className="row">
              <div
                className="card"
                style={{
                  width: isSmallScreen ? "150px" : "140px",
                  height: isSmallScreen ? "190px" : "200px",
                }}
              >
                <div className="card-icon card-icon-green">
                  <img src={braid} alt="" />
                </div>
                <div className="card-title">Braid</div>
                <div className="card-value card-title-green">{totalBraid}</div>
              </div>
            </div>
            <div className="articles-section">
              <div className="articles-card-title">Most Purchased Items</div>
              <div className={`row-card ${isSmallScreen ? "scrollable" : ""}`}>
                {isSmallScreen ? (
                  <div className="articles-card">
                    <div className="articles-card-img">
                      <img src={braid1} alt="" />
                    </div>
                    <div className="articles-card-info">
                      <div className="titleColored">
                        <span style={{ color: "#000000" }}>
                          HAIR <span>BOB</span>
                        </span>
                      </div>
                      <div className="titleColored1">
                        <span>
                          101564{" "}
                          <span style={{ color: "#000000" }}>purchases</span>
                        </span>
                      </div>
                      <div className="titleColored1">
                        <div className="row-card1">
                          <img src={like} alt="" />
                          <span>54 K</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  // Render all cards for larger screens
                  <>
                    {["BOB", "BOX BRAIDS", "MICRO-BRAIDS"].map(
                      (item, index) => (
                        <div className="articles-card" key={index}>
                          <div className="articles-card-img">
                            <img src={braid1} alt="" />
                          </div>
                          <div className="articles-card-info">
                            <div className="titleColored">
                              <span style={{ color: "#000000" }}>
                                HAIR <span>{item}</span>
                              </span>
                            </div>
                            <div className="titleColored1">
                              <span>
                                101564{" "}
                                <span style={{ color: "#000000" }}>
                                  purchases
                                </span>
                              </span>
                            </div>
                            <div className="titleColored1">
                              <div className="row-card1">
                                <img src={like} alt="" />
                                <span>54 K</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            </div>

            <div class="employees-section">
              <div class="employees-title-section">
                <div className="employees-title-section-name">
                  <span> Employees</span>
                </div>
                <div
                  className="employees-title-section-btn"
                  onClick={() => {
                    showAllEmployer
                      ? handleViewLessEmployer()
                      : handleViewAllEmployer();
                  }}
                >
                  {showAllEmployer ? (
                    <span>View Less</span>
                  ) : (
                    <span> View All </span>
                  )}
                </div>
              </div>
              <div className="divider"></div>
              <div class="employees-section-content">
                {tronkedEmployer.length > 0 ? (
                  tronkedEmployer.map((emp, index) => (
                    <>
                      <li class="employees-list-item" key={index}>
                        {emp.profil && emp.profil !== null ? (
                          <img
                            src={`data:image;base64,${emp.profil}`}
                            alt="profil"
                            className="employees-list-item-img"
                          />
                        ) : (
                          <FaUser className="employees-list-item-img" />
                        )}
                        <div class="employees-list-item-info">
                          <div>
                            <div class="employees-list-item-name">
                              {emp.firstName + " " + emp.lastName}
                            </div>
                            <div class="employees-list-item-role">
                              {emp.poste || "Employer"}
                            </div>
                          </div>
                          <div class="employees-list-item-hours">
                            {" "}
                            {emp.workHour + " " * "hours/week" ||
                              "35 hours/week"}
                          </div>
                        </div>
                      </li>
                      <div className="divider"></div>
                    </>
                  ))
                ) : (
                  <div>No Employer yet</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer limit={1} position="top-center" />
    </div>
  );
}

export default Center;
